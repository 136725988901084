.product__item {
  background: #ccc;

  width: 230px;
  padding: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.25rem;
  height: auto;
  margin-bottom: 3rem;
  margin-top: 3rem;
  height: 275px;

  transition: all 200ms ease-in-out;

  position: relative;
}

.product__item-img {
  position: absolute;
  background-color: white;
  width: 130px;
  height: 150px;
  border-radius: 0.25rem;
  transition: width 200ms ease-in-out, height 200ms ease-in-out;
}

.product__img {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  /* transition: 0.4s; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.product__content span.title {
  position: absolute;
  top: 165px;
  left: 11px;
  right: 21px;
  color: #000;
  font-size: 0.9rem;
  height: 72px;
  overflow: hidden;
  font-weight: 300;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
}

.product__content span.title span.description {
  opacity: 0.7;
  font-size: 0.7rem;
}

.product__content .increase__decrease-btn {
  position: absolute;
  top: 50px;
  bottom: initial;
  right: 18px;
  width: 56px;
  left: initial;
  display: flex;
  flex-direction: column;
}

.product__price,
.product__sale_price {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 500;
  font-size: 0.9rem;
  color: #000;
  transition: all 200ms ease-in-out;
}

.product__price.has-sale {
  bottom: 30px;
  font-size: 0.75rem;
  text-decoration: line-through;
}

.inner__regular_price {
  display: none;
}

.addTOCart__btn {
  position: absolute;
  top: 60px;
  right: 18px;
  border: none;
  background: #000;
  border-radius: 999px;
  font-size: 1.5rem;
  opacity: 0.8;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  outline: none;
}

.order-button {
  border: none;
  padding: 30px 80px;
  background: var(--primary-color);
  color: var(--text-color);
  border-radius: 5px;
  font-size: 1rem;
  transition: all 200ms ease-in-out;
}

.product__item.mobile {
  width: 400px;
  height: 115px;
  border-radius: 0;
  margin-bottom: 0;
}

.product__item.mobile:first-child {
  margin-top: 0;
}

.addTOCart__btn.mobile {
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
}

.product__item-img.mobile {
  width: 70px;
  height: 78px;
  margin-top: 16px;
}

.product__content.mobile span.title {
  top: 28px;
  left: 90px;
  right: 100px;
  font-size: 0.75rem;
  height: 50px;
}

.product__content.mobile span.title span.description {
  font-size: 0.6rem;
}

.product__price.mobile,
.product__sale_price.mobile {
  bottom: initial;
  top: 82px;
  left: 90px;
  font-size: 0.75rem !important;
}

.product__price.has-sale.mobile {
  display: none;
}

.inner__regular_price.mobile {
  display: inline-block;
  text-decoration: line-through;
  font-size: 0.6rem;
}

.product__content.mobile .increase__decrease-btn {
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 80px;
  flex-direction: row;
}

.product__content.mobile .increase__decrease-btn .quantity {
  margin: 0 5px;
}
